<!--
 * @Author: wang peng
 * @Description: 历史订单列表  
-->
<template>
  <scroll-layout class="directConnectionPile-list">
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @clean="handleSearchClean"
        @click="handleSearchConfirm"></ykc-search>
    </template>
    <div>
      <ykc-table
        ref="YkcTable"
        operateFixedType="right"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button v-rbac="'order:test:charge:his:column'" type="plain" @click="customColumns">
              自定义列
            </ykc-button>
            <ykc-button v-rbac="'order:test:charge:his:export'" type="plain" @click="doExportFile">
              导出
            </ykc-button>
          </div>
        </template>
        <ykc-pagination
          align="right"
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.size"
          :current-page.sync="pageInfo.current"
          @current-change="handlePaginationCurrentChange"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
      <!--自定义列弹窗 -->
      <ykc-operation-dialog
        title="自定义列"
        show-footer
        :show.sync="showDialog"
        :before-close="onCLose"
        :before-cancel="onCLose"
        :before-confirm="beforeConfirm">
        <div slot="content">
          <el-form
            size="mini"
            label-width="80px"
            ref="customForm"
            class="customForm"
            :model="customForm"
            :rules="customRules">
            <el-form-item label="列选项:" prop="checkOption">
              <ykc-checkbox
                :configSet="{
                  label: 'label',
                  value: 'value',
                }"
                :data="customData"
                v-model="customForm.checkOption"
                @change="checkboxChange"></ykc-checkbox>
            </el-form-item>
          </el-form>
        </div>
      </ykc-operation-dialog>
    </div>
  </scroll-layout>
</template>
<script>
  import {
    code,
    getLastTime,
    localSetItem,
    localGetItem,
    sessionGetItem,
    offlineExport,
  } from '@/utils';
  import {
    orderPage,
    barrierPage,
    customerOrg,
    propertySettleManage,
    operatorData,
  } from '@/service/apis';

  export default {
    name: 'historicalOrderList',
    components: {},
    inject: ['reload'],
    data() {
      return {
        orderTypeData: [
          { id: 0, name: '直联订单' },
          { id: 1, name: '流量订单' },
          { id: 2, name: '桩联订单' },
          { id: 3, name: '双向互联' },
        ], // 订单类型
        settleTypeData: [
          { id: 0, name: '正常结算' },
          { id: 1, name: '异常单手动结算' },
          { id: 2, name: '异常单自动结算' },
          { id: 3, name: '平台延迟结算' },
          { id: 4, name: '三方延迟结算' },
        ], // 结算类型
        stopChargeReason: [], // 订单结束原因
        propertyData: [], // 物业
        userOperatorList: [], // 所属商户
        showDialog: false,
        isProperty: false, // 是否是物业
        searchParams: {
          date: [],
          tradeTime: [], // 结算时间
          startChargingTimeStart: '',
          startChargingTimeEnd: '',
          userAccount: '',
          plateNumber: '',
          stationId: '',
          propertyId: '',
          operatorIds: [],
          userCompanyId: '',
          tradeSeq: '',
          cardNo: '',
          tradeStatus: '',
          discountNo: '',
          zdlTradeSeq: '',
          recordIds: [],
          chargingSource: '',
          orderType: null,
          settleType: null,
          chargingType: '',
          isTestStation: 1,
          isShare: 0,
        },
        stationIdData: [],
        userCompanyIdData: [], // 归属机构数据
        customForm: {
          checkOption: [0, 1, 2, 3, 4, 5, 6, 37],
        },
        customData: [
          {
            value: 0,
            label: '充电单号',
          },
          {
            value: 37,
            label: '终端编码',
          },
          {
            value: 1,
            label: '充电用户',
          },
          {
            value: 2,
            label: '电量（度）',
          },
          {
            value: 3,
            label: '订单金额',
          },
          {
            value: 4,
            label: '结算类型',
          },
          {
            value: 5,
            label: '电站名称',
          },
          {
            value: 6,
            label: '结算时间',
          },
          {
            value: 7,
            label: '归属机构',
          },
          {
            value: 8,
            label: '车牌号码',
          },
          {
            value: 9,
            label: '商户',
          },
          {
            value: 10,
            label: '应收（元）',
          },
          {
            value: 11,
            label: '实付（元）',
          },
          {
            value: 12,
            label: '起始SOC',
          },
          {
            value: 13,
            label: '流量互联单号',
          },
          {
            value: 14,
            label: '桩互联单号',
          },
          {
            value: 15,
            label: '充电卡号',
          },
          {
            value: 16,
            label: '电桩品牌',
          },
          {
            value: 17,
            label: '电桩型号',
          },
          {
            value: 18,
            label: '电桩功率',
          },
          {
            value: 19,
            label: '电桩类型',
          },
          {
            value: 20,
            label: '启动来源',
          },
          {
            value: 21,
            label: '车辆VIN',
          },
          {
            value: 22,
            label: '开始时间',
          },
          {
            value: 23,
            label: '启动时长',
          },
          {
            value: 24,
            label: '订单电费',
          },
          {
            value: 25,
            label: '订单服务费',
          },
          {
            value: 26,
            label: '平台电费折扣',
          },
          {
            value: 27,
            label: '平台服务费折扣',
          },
          {
            value: 28,
            label: '商户电费折扣',
          },
          {
            value: 29,
            label: '商户服务费折扣',
          },
          {
            value: 30,
            label: '电费卡券抵扣',
          },
          {
            value: 31,
            label: '服务费卡券抵扣',
          },
          {
            value: 32,
            label: '支付钱包',
          },
          {
            value: 33,
            label: '支付状态',
          },
          {
            value: 34,
            label: '订单类型',
          },
          {
            value: 35,
            label: '订单结束原因',
          },
          {
            value: 36,
            label: '充电类型',
          },
        ],
        customRules: {
          checkOption: [{ required: true, message: '请至少选择一个列名称', trigger: 'blur' }],
        },
        tableTitle: '订单列表',
        tableData: [],
        tableOperateButtons: [], // 列表操作
        tableColumns: [
          { label: '充电单号', prop: 'tradeSeq', minWidth: '200px', id: 0 },
          { label: '终端编码', prop: 'gunCode', minWidth: '160px', id: 37 },
          {
            label: '充电用户',
            prop: 'nickName',
            minWidth: '220px',
            scopedSlots: {
              default: ({ row }) => {
                return (
                  <span>
                    {row.userAccount}({row.nickName})
                  </span>
                );
              },
            },
            id: 1,
          },
          { label: '电量(度)', prop: 'chargedPower', minWidth: '120px', id: 2 },
          { label: '订单金额(元)', prop: 'totalAmount', minWidth: '120px', id: 3 },
          {
            label: '结算类型',
            prop: 'settleType',
            minWidth: '150px',
            scopedSlots: {
              default: ({ row }) => {
                const title =
                  this.settleTypeData?.find(o => Number(o.id) === Number(row.settleType))?.name ??
                  '——';
                return <span>{title}</span>;
              },
            },
            id: 4,
          },
          { label: '电站名称', prop: 'stationName', minWidth: '150px', id: 5 },
          { label: '结算时间', prop: 'tradeTime', minWidth: '180px', id: 6 },

          { label: '归属机构', prop: 'companyName', minWidth: '150px', id: 7 },
          {
            label: '车牌号码',
            prop: 'plateNumber',
            minWidth: '150px',
            id: 8,
          },
          { label: '商户', prop: 'stationOperatorName', minWidth: '150px', id: 9 },
          {
            label: '应收(元)',
            prop: 'operatorActualIncome',
            minWidth: '150px',
            id: 10,
          },
          {
            label: '实付(元)',
            prop: 'userActualIncome',
            minWidth: '150px',
            scopedSlots: {
              default: ({ row }) => {
                const startStatus = row.tradeStatus === '11' ? 'status-red' : '';
                return (
                  <span class={[startStatus]}>
                    {row.tradeStatus === '11' ? '未支付' : `${row.userActualIncome}`}
                  </span>
                );
              },
            },
            id: 11,
          },
          { label: '起始SOC(%)', prop: 'startSoc', minWidth: '150px', id: 12 },
          { label: '流量互联单号', prop: 'zdlTradeSeq', minWidth: '150px', id: 13 },
          { label: '桩联互联单号', prop: 'pileTradeSeq', minWidth: '150px', id: 14 },
          { label: '充电卡号', prop: 'cardNo', minWidth: '150px', id: 15 },
          { label: '电桩品牌', prop: 'name', minWidth: '150px', id: 16 },
          { label: '电桩型号', prop: 'modelName', minWidth: '150px', id: 17 },
          { label: '电桩功率(KW)', prop: 'pilePower', minWidth: '150px', id: 18 },
          {
            label: '电桩类型',
            prop: 'pileType',
            minWidth: '150px',
            scopedSlots: {
              default: ({ row }) => {
                return <span>{row.pileType === 1 ? '直流' : '交流'}</span>;
              },
            },
            id: 19,
          },
          {
            label: '启动来源',
            prop: 'chargingSource',
            minWidth: '150px',
            scopedSlots: {
              default: ({ row }) => {
                const cls = [
                  { id: 1, name: '扫码启动' },
                  { id: 2, name: '扫码启动' },
                  { id: 3, name: '刷卡充电' },
                  { id: 4, name: '即插即充' },
                  { id: 5, name: '流量方' },
                  { id: 6, name: '预约充电' },
                  { id: 7, name: '离线卡' },
                ][Number(row.chargingSource) - 1];

                if (!cls) {
                  return '一';
                }

                return <span>{cls.name}</span>;
              },
            },
            id: 20,
          },
          { label: '车辆VIN', prop: 'carVinCode', minWidth: '150px', id: 21 },
          { label: '开始时间', prop: 'startTime', minWidth: '180px', id: 22 },
          { label: '启动时长(S)', prop: 'startChargedTime', minWidth: '150px', id: 23 },
          {
            label: '订单电费(元)',
            prop: 'chargedAmount',
            minWidth: '150px',
            id: 24,
          },
          {
            label: '订单服务费(元)',
            prop: 'parkedAmount',
            minWidth: '150px',
            id: 25,
          },
          {
            label: '平台电费折扣(元)',
            prop: 'platformActivityAndMemberChargedAmount',
            minWidth: '150px',
            id: 26,
          },
          {
            label: '平台服务费折扣(元)',
            prop: 'platformActivityAndMemberServiceDiscountAmount',
            minWidth: '150px',
            id: 27,
          },
          {
            label: '商户电费折扣(元)',
            prop: 'businessActivityChargedDiscountAmount',
            minWidth: '150px',
            id: 28,
          },
          {
            label: '商户服务费折扣(元)',
            prop: 'businessActivityServiceDiscountAmount',
            minWidth: '150px',
            id: 29,
          },
          {
            label: '电费卡券抵扣(元)',
            prop: 'creditCardChargedDiscountAmount',
            minWidth: '150px',
            id: 30,
          },
          {
            label: '服务费卡券抵扣(元)',
            prop: 'creditCardServiceDiscountAmount',
            minWidth: '150px',
            id: 31,
          },
          {
            label: '支付钱包',
            prop: 'payType',
            minWidth: '150px',
            scopedSlots: {
              default: ({ row }) => {
                const cls = localGetItem('dictionary')?.pay_type[row.payType] || '';

                if (!cls) {
                  return '一';
                }

                return <span>{cls.name}</span>;
              },
            },
            id: 32,
          },
          {
            label: '支付状态',
            prop: 'tradeStatus',
            minWidth: '150px',
            scopedSlots: {
              default: ({ row }) => {
                const startStatus = row.tradeStatus === '11' ? 'status-red' : '';
                return (
                  <span class={[startStatus]}>
                    {row.tradeStatus === '11' ? '待支付' : `已支付`}
                  </span>
                );
              },
            },
            id: 33,
          },
          {
            label: '订单类型',
            prop: 'orderType',
            minWidth: '150px',
            scopedSlots: {
              default: ({ row }) => {
                const orderType =
                  this.orderTypeData.find(o => o.id === row.orderType)?.name ?? '——';
                return (
                  <div>
                    <span title={orderType}>{orderType}</span>
                  </div>
                );
              },
            },
            id: 34,
          },
          {
            label: '订单结束原因',
            prop: 'stopChargeReason',
            minWidth: '150px',
            scopedSlots: {
              default: ({ row }) => {
                const title =
                  this.stopChargeReason?.find(o => o.code === row.stopChargeReason)?.name ?? '——';
                return <span>{title}</span>;
              },
            },
            id: 35,
          },
          {
            label: '充电类型',
            prop: 'chargingType',
            scopedSlots: {
              default: ({ row }) => {
                const typeMap = {
                  1: '有序充电',
                  0: '正常充电',
                };
                const type = typeMap[row.chargingType] || '——';
                return (
                  <div>
                    <span title={type}>{type}</span>
                  </div>
                );
              },
            },
            minWidth: '150px',
            id: 36,
          },
        ],
        pageInfo: {
          current: 1,
          size: 10,
        },
      };
    },
    created() {
      this.stopChargeReason = localGetItem('dictionary').stop_charge_reason || [];
      this.isProperty = JSON.parse(sessionGetItem('propertyFlag'));
      if (this.isProperty) {
        this.searchParams.propertyId = JSON.parse(sessionGetItem('propertyId'));
      }
      // 页面跳转传参
      if (this.$route.query.data) {
        const query = JSON.parse(this.$route.query.data);
        console.log('query+++', query);
        if (query.userCardCouponId && query.userCardCouponId !== null) {
          this.searchParams.discountNo = query.userCardCouponId;
        }

        if (query.recordIds && query.recordIds !== null) {
          this.searchParams.recordIds = query.recordIds;
        }
      }

      // 近30天初始化时间
      if (this.searchParams.recordIds && this.searchParams.recordIds.length) {
        this.searchParams.date = [];
        this.searchParams.startChargingTimeStart = '';
        this.searchParams.startChargingTimeEnd = '';
      } else {
        const startDate = getLastTime('yyyy-MM-dd', 30);
        const endDate = getLastTime('yyyy-MM-dd', 0);
        this.searchParams.date = [startDate, endDate];
        this.searchParams.startChargingTimeStart = startDate;
        this.searchParams.startChargingTimeEnd = endDate;
      }

      // 自定义列处理
      const customForm = localGetItem('historicalOrderCustomForm');
      console.log('----', customForm);
      if (customForm) {
        this.customForm.checkOption = customForm;
        this.tableColumns = this.tableColumns.filter(item => {
          return customForm.includes(item.id);
        });
        console.log('----1', this.tableColumns);
      } else {
        this.tableColumns = this.tableColumns.filter(item => {
          return ![
            7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
            30, 31, 32, 33, 34, 35, 36,
          ].includes(item.id);
        });
      }
      this.requestCompanyData();
      this.requestStationData();
      this.requestPropertyList();
      this.requestUserOperatorList();
      this.requestList();
      this.initData();
    },
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcDatePicker',
            key: 'date',
            label: '开始充电 ',
            placeholder: '请选择充电时间',
          },
          {
            comName: 'YkcInput',
            key: 'userAccount',
            label: '用户账号',
            placeholder: '请输入用户账号',
          },
          {
            comName: 'YkcInput',
            key: 'plateNumber',
            label: '车牌号码',
            placeholder: '请输入车牌号码',
          },
          {
            comName: 'YkcInput',
            key: 'zdlTradeSeq',
            label: '互联单号',
            placeholder: '请输入互联单号',
          },
          {
            comName: 'YkcInput',
            key: 'tradeSeq',
            label: '充电单号',
            placeholder: '请输入充电单号',
          },
          {
            comName: 'YkcInput',
            key: 'cardNo',
            label: '充电卡号',
            placeholder: '请输入充电卡号',
          },
          {
            remote: true,
            comName: 'YkcDropdown',
            key: 'userCompanyId',
            label: '归属机构',
            placeholder: '请输入归属机构',
            data: this.userCompanyIdData,
          },
          {
            remote: true,
            comName: 'YkcDropdown',
            key: 'propertyId',
            label: '所属物业',

            placeholder: '请输入所属物业',
            data: this.propertyData,
          },
          {
            remote: true,
            comName: 'YkcDropdown',
            key: 'stationId',
            label: '电站名称',
            placeholder: '请输入电站名称',
            data: this.stationIdData,
          },
          {
            comName: 'YkcInput',
            key: 'discountNo',
            label: '卡券编码',
            placeholder: '请输入卡券编码',
          },
          {
            clearable: true,
            comName: 'YkcDropdown',
            key: 'tradeStatus',
            label: '支付状态',
            placeholder: '请选择支付状态',
            data: [
              { id: '10', name: '已支付' },
              { id: '11', name: '待支付' },
            ],
          },
          {
            clearable: true,
            comName: 'YkcDropdown',
            key: 'chargingSource',
            label: '启动来源',
            placeholder: '请选择启动来源',
            data: [
              { id: '1', name: '扫码启动' },
              { id: '3', name: '刷卡充电' },
              { id: '4', name: '即插即充' },
              { id: '5', name: '流量方' },
              { id: '6', name: '预约充电' },
              { id: '7', name: '离线卡' },
            ],
          },
          {
            clearable: true,
            comName: 'YkcDropdown',
            key: 'orderType',
            label: '订单类型',
            placeholder: '请选择订单类型',
            data: this.orderTypeData,
          },
          {
            clearable: true,
            comName: 'YkcDropdown',
            key: 'settleType',
            label: '结算类型',
            placeholder: '请选择结算类型',
            data: this.settleTypeData,
          },
          {
            comName: 'YkcDatePicker',
            key: 'tradeTime',
            label: '结算时间 ',
            placeholder: '请选择结算时间',
          },
          {
            comName: 'YkcDropdown',
            key: 'chargingType',
            label: '充电类型',
            placeholder: '请选择充电类型',
            data: [
              { id: 0, name: '正常充电' },
              { id: 1, name: '有序充电' },
            ],
          },

          {
            comName: 'YkcDropdown',
            key: 'operatorIds',
            multiple: true,
            label: '归属商户',
            placeholder: '请选择归属商户',
            data: this.userOperatorList,
          },
          {
            comName: 'YkcInput',
            key: 'gunCode',
            label: '终端编码',
            placeholder: '请输入终端编码',
          },
        ];
      },
    },
    methods: {
      /**
       * 初始数据
       */
      initData() {
        this.tableOperateButtons = [
          {
            id: '2',
            enabled: () => code('order:test:charge:his:detail'),
            text: '详情',
            handleClick: (btn, { row }) => {
              this.$router.push({
                path: '/orderManagement/test/historicalOrder/Detail',
                query: { recordId: row.recordId },
              });
            },
          },
        ];
      },
      /**
       * 导出
       */
      doExportFile() {
        offlineExport({
          downloadType: 'history_order',
          jsonNode: {
            ...this.searchParams,
          },
        });
      },
      /**
       * 自定义列
       * */
      customColumns() {
        this.showDialog = true;
      },
      /**
       * 组件change方法调用信息提示
       */
      checkboxChange(e) {
        this.customForm.checkOption = e;
        console.log('this.customForm+++', this.customForm);
      },
      /**
       *  弹窗确认
       * */
      beforeConfirm(done) {
        console.log('确认', this.customForm);
        this.$refs.customForm.validate(valid => {
          if (valid) {
            localSetItem('historicalOrderCustomForm', JSON.stringify(this.customForm.checkOption));
            done();
            this.reload();
          }
        });
      },
      /**
       * 弹窗关闭
       * */
      onCLose() {
        this.showDialog = false;
      },
      /**
       * 获取归属电站数据
       */
      requestStationData() {
        barrierPage
          .findStationList({})
          .then(res => {
            console.log('获取归属电站数据+++++', res);
            this.stationIdData = res.map(item => ({
              id: item.stationId,
              name: item.stationName,
            }));
          })
          .catch(err => {
            console.log(err);
          });
      },

      /**
       * 获取物业列表
       */
      requestPropertyList() {
        propertySettleManage
          .propertyList({})
          .then(res => {
            this.propertyData = (res || []).map(item => {
              return {
                id: item.id,
                name: item.propertyName,
              };
            });
            if (this.isProperty) {
              this.searchParams.propertyId = res[0].id;
            }
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取归属商户数据
       */
      requestUserOperatorList() {
        operatorData
          .operatorDownList({})
          .then(res => {
            console.log('获取归属商户数据+++++', res);
            res.map(item => {
              return this.userOperatorList.push({
                id: item.id,
                name: item.operatorName,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取归属机构数据
       */
      requestCompanyData() {
        customerOrg
          .queryOrg({})
          .then(res => {
            console.log('获取归属机构数据+++++', res);
            this.userCompanyIdData = res || [];
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 点击筛选查询按钮
       * */
      handleSearchConfirm(searchData) {
        console.log('searchData++++', searchData);
        this.pageInfo.current = 1;
        Object.assign(this.searchParams, searchData);

        if (searchData.date && searchData.date.length > 0) {
          [this.searchParams.startChargingTimeStart, this.searchParams.startChargingTimeEnd] =
            searchData.date;
        }
        if (
          searchData.tradeTime &&
          Array.isArray(searchData.tradeTime) &&
          searchData.tradeTime.length > 0
        ) {
          [this.searchParams.tradeTimeStart, this.searchParams.tradeTimeEnd] = searchData.tradeTime;
        }
        this.requestList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        console.log('清空查询', form);
        Object.assign(this.searchParams, form);

        // 近30天初始化时间
        const startDate = getLastTime('yyyy-MM-dd', 30);
        const endDate = getLastTime('yyyy-MM-dd', 0);

        this.searchParams.date = [startDate, endDate];
        this.searchParams.startChargingTimeStart = startDate;
        this.searchParams.startChargingTimeEnd = endDate;
      },
      /**
       * 分页器页数
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.requestList();
      },
      /**
       * 分页器条数
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.requestList();
      },
      /**
       * 请求列表数据
       */
      requestList() {
        const reqParams = {
          ...this.searchParams,
          ...this.pageInfo,
        };

        orderPage
          .historyChargingOrderPage(reqParams)
          .then(res => {
            this.tableData = res.records || [];
            this.pageInfo.total = res.total || 0;
          })
          .catch(err => {
            console.log(err);
          });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .ykc-checkbox .icon-check {
    top: 7px;
  }

  .directConnectionPile-list {
    .ykc-table {
      .el-table .el-table__row {
        .cell .userStatus {
          padding-left: 8px;
          position: relative;
        }
        .cell .userStatus::after {
          display: block;
          content: '';
          width: 5px;
          height: 5px;
          border-radius: 100%;
          background: #d0021b;
          position: absolute;
          top: 5.5px;
          left: 0;
        }
        .cell .status-red {
          color: #d0021b;
        }

        .cell .status-blue::after {
          background: #4d8cfd;
        }

        .cell .status-green::after {
          background: #02c498;
        }

        .cell .status-black::after {
          background: #9b9b9b;
        }
      }

      .sealBtn {
        color: #4d8cfd;
        cursor: pointer;
      }
    }
  }

  ::v-deep.ykc-checkbox .icon-check {
    top: 8px;
  }

  ::v-deep.customForm .el-form-item,
  ::v-deep.customForm .el-form-item--mini.el-form-item,
  .el-form-item--small.el-form-item {
    margin-bottom: 0;
  }
</style>
